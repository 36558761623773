import React from "react";
import { Link, graphql } from "gatsby";
import Masonry from "react-masonry-component";
import Img from "gatsby-image";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

const IndexPage = ({ data }) => (
  <Layout>
    <Masonry className="showcase">
      {data.allDatoCmsWork.edges.map(({ node: work }) => {
        return (
          <div key={work.id} className="showcase__item">
            <figure className="card">
              <a href={work.demourl} className="card__image" target="_blank">
                <Img fluid={work.coverImage.fluid} />
              </a>
              <figcaption className="card__caption">
                <h6 className="card__title">
                  <a href={work.demourl}>{work.title}</a>
                </h6>
                <div className="card__description">
                  <p>{work.excerpt}</p>
                </div>
                <div className="card__ctas">
                  <a href={work.githuburl} target="_blank" class='demo__cta'>Source code</a>
                  <a href={work.blogurl} target="_blank" class='demo__cta'>Tutorial</a>
                </div>
              </figcaption>
            </figure>
          </div>
        );
      })}
    </Masonry>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          demourl
          blogurl
          githuburl
          coverImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
